var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { style: "color: " + _vm.getFontColor() }, [
    _vm._v(
      "\n  " +
        _vm._s(_vm.cellData[_vm.metricName]) +
        _vm._s(_vm.format === "percent" ? "%" : "") +
        " " +
        _vm._s(
          _vm.showComparison
            ? " (" +
                (_vm.getDifference > 0
                  ? "↑ "
                  : _vm.getDifference < 0
                  ? "↓ "
                  : "") +
                _vm.getAbsDifference() +
                ")"
            : ""
        ) +
        "\n  "
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }