<template>
  <div :style="`color: ${getFontColor()}`">
    {{ cellData[metricName] }}{{ format === 'percent' ? '%' : '' }} {{ showComparison ? ` (${getDifference > 0 ? '&#8593; ' : getDifference &lt; 0 ? '&#8595; ' : ''}${getAbsDifference()})` : '' }}
    <!-- <v-icon
      small
      right
      color="red"
    >
      mdi-triangle-small-down
    </v-icon> -->
  </div>
</template>

<script>
// import { greenRedGradientLight8 } from '@/shared/constants'
import { isEmpty } from 'lodash'

export default {
  name: 'ServiceLineReportCellTemplate',

  props: {
    metricName: {
      type: String,
      default: 'received'
    },
    cellData: {
      type: Object,
      default: () => {}
    },
    comparisonCellData: {
      type: Object,
      default: () => {}
    },
    format: {
      type: String,
      default: 'percent'
    },
    colorCodeComparison: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      green10: [
        '#ffffff',
        '#f3f8eb',
        '#e7f2d7',
        '#daebc3',
        '#cee5af',
        '#c1de9b',
        '#b4d787',
        '#a7d073',
        '#99ca5f',
        '#8bc34a'
      ],
      green: '#4CAF50',
      red10: [
        '#ffffff',
        '#ffedea',
        '#ffdbd5',
        '#ffc8c1',
        '#ffb6ad',
        '#ffa49a',
        '#fd9187',
        '#f97d74',
        '#f56962',
        '#ef5350'
      ],
      red: '#F44336'
    }
  },

  computed: {
    showComparison () {
      return !isEmpty(this.comparisonCellData) && this.comparisonCellData !== null && this.comparisonCellData !== undefined
    },
    getDifference () {
      if (!this.showComparison) return 0
      var dif = this.cellData[this.metricName] - this.comparisonCellData[this.metricName]
      return dif.toFixed(2)
    }
    // redGreenGradient8 () {
    //   return [
    //     greenRedGradientLight8[7],
    //     greenRedGradientLight8[6],
    //     greenRedGradientLight8[5],
    //     greenRedGradientLight8[4],
    //     greenRedGradientLight8[3],
    //     greenRedGradientLight8[2],
    //     greenRedGradientLight8[1],
    //     greenRedGradientLight8[0]
    //   ]
    // }
  },

  methods: {
    getAbsDifference () {
      return Math.abs(this.getDifference)
    },
    getOperator () {
      if (!this.showComparison || this.getDifference === 0) return ''
      return this.getDifference > 0 ? '+' : ''
    },
    getFontColor () {
      if (!this.showComparison || this.getDifference === 0) return '#000000'
      return this.getDifference > 0 ? this.green : this.getDifference < 0 ? this.red : '#000000'
    },
    getColorClass () {
      if (this.format !== 'percent' || !this.colorCodeComparison) return this.green10[0]
      if (this.showComparison) {
        let dif = this.getDifference
        let colors = dif >= 0 ? this.green10 : this.red10
        dif = this.getAbsDifference()
        if (dif < 1) return colors[0]
        if (dif < 2) return colors[1]
        if (dif < 3) return colors[2]
        if (dif < 5) return colors[3]
        if (dif < 8) return colors[4]
        if (dif < 12) return colors[5]
        if (dif < 16) return colors[6]
        if (dif < 20) return colors[7]
        if (dif < 25) return colors[8]
        return colors[9]
      } else {
        let metric = this.cellData[this.metricName]
        if (metric < 3) return this.green10[0]
        if (metric < 6) return this.green10[1]
        if (metric < 10) return this.green10[2]
        if (metric < 15) return this.green10[3]
        if (metric < 20) return this.green10[4]
        if (metric < 25) return this.green10[5]
        if (metric < 35) return this.green10[6]
        if (metric < 50) return this.green10[7]
        if (metric < 75) return this.green10[8]
        return this.green10[9]
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
